#AnimatedFolder {
  .folderStack {
    position: relative;
    height: 500px;
    padding: 4% 0;
    .folderImage {
      position: absolute;
      width: 300px; // 필요에 따라 조절
      &.fourth {
        cursor: pointer;
        animation: moveUpDown 0.5s ease-in-out infinite alternate;
      }
    }
  }
  p {
    margin-top: 70px;
    color: #0b1115;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    line-height: 141%; /* 36.66px */
    letter-spacing: -1.04px;
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    // 60% {
    //   transform: translateY(-50px);
    // }
    100% {
      transform: translateY(-55px); /* -50px에서 머무는 효과 */
    }
  }
}
