#CustomerReviews {
  .reviewSlider {
    margin-top: 50px;

    .slideItem {
      padding: 0 3%;
      box-sizing: border-box;
      img {
        width: 100%;
      }
      .reviewName {
        display: block;
        margin: 18px 0 5px 0;
        color: #909090;
        font-size: 16px;
        font-weight: 500;
        line-height: 141%; /* 22.56px */
        letter-spacing: -0.64px;
      }
      .reviewText {
        color: #0b1115;
        font-size: 20px;
        font-weight: 500;
        line-height: 141%; /* 28.2px */
        letter-spacing: -0.8px;
      }
    }
    .customArrow {
      background: none;
      border: none;
      position: absolute;
      z-index: 1;
      top: 25%;
      cursor: pointer;
      &.next {
        right: -4%;
      }
      &.prev {
        left: -4%;
      }
    }
  }
}
