.mainHome {
  overflow-x: hidden;
  > section {
    width: 100%;
    &:not(:nth-of-type(1)) {
      padding: 150px 0;
      position: relative;
    }
    @media (max-width: 1120px) {
      &:not(:nth-of-type(1)) {
        padding: 80px 0;
      }
    }

    .sectionInner {
      width: 1120px;
      margin: 0 auto;
    }

    .sectionHi {
      width: 1120px;
      margin: 0 auto;
    }
    @media (max-width: 1120px) {
      .sectionInner,
      .sectionHi {
        width: 90%;
      }
    }
    @media (max-width: 1120px) {
      .sectionInner,
      .sectionHi {
        width: 88%;
      }
    }
    .slider-container {
      position: relative;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .section_titleBox {
    width: 100%;
    text-align: center;
    .section_subtitle {
      color: #06f;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 25px;
    }
    .section_title {
      color: #0b1115;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }
    .section_description {
      color: #0b1115;
      font-size: 30px;
      font-weight: 500;
      line-height: 60px;
      letter-spacing: -1.2px;
    }
  }

  /////////////////////////////////////
  .fade-up {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.3s;
    will-change: opacity, transform;
    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @for $i from 1 through 10 {
    .fade-delay-#{$i} {
      transition-delay: #{0.2 * $i}s;
    }
  }
}
