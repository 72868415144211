#slickSection {
  background-color: #072251;
  .section_titleBox {
    text-align: left;
    color: white;
    width: 50%;
  }
  .section_title {
    color: white;
  }
  .section_description {
    margin-top: 20px;
    color: white;
    line-height: 48px;
  }
  .sectionInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .slick-container {
    margin-left: 10%;
    width: 50%;
  }

  // ttttttttttttttttttttttttttttttt

  .carousel-container {
    perspective: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    position: relative;
    .nav {
      background: rgba(0, 0, 0, 0.5);
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 40px;
      border: none;
      border-radius: 50%;
      font-size: 1.8rem;
      color: #fff;
      cursor: pointer;
      z-index: 10;
      // opacity: 0.5;
      position: absolute;
      &.next {
        right: -10%;
      }
      &.prev {
        left: -10%;
      }
    }
    .carousel {
      position: relative;
      width: 100%;
      height: 514px; // 이미지 높이에 맞춤
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .card {
      position: absolute;
      transition: all 0.5s ease;
      opacity: 0;
      transform: scale(0.7);
      z-index: 0;
      pointer-events: none;

      .card-inner {
        width: 264px;
        height: 514px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.active {
        transform: scale(1) translateX(0);
        opacity: 1;
        z-index: 3;
        pointer-events: auto;
      }

      &.left {
        transform: scale(0.7) translateX(-200px);
        opacity: 0.6;
        z-index: 2;
      }

      &.right {
        transform: scale(0.7) translateX(200px);
        opacity: 0.6;
        z-index: 2;
      }

      &.hidden {
        transform: scale(0.7);
        opacity: 0;
        z-index: 0;
      }
    }

    .bg-img,
    .top-img {
      pointer-events: none;
      user-select: none;
    }
    .top-img {
      position: absolute;
      bottom: 0;
    }
  }
}
