#MainSection {
  &.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #0b1115;
    .background-video {
      height: 115%;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%); /* 정중앙 정렬 */
      z-index: 1;
      object-fit: cover;
    }
    .content {
      position: absolute;
      left: 20%;
      z-index: 2;
      max-width: 1120px;
      margin: 0 auto;
      height: 100%;
      font-family: Pretendard;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      h2 {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 6px;
      }
      h3 {
        font-size: 40px;
        font-weight: 500;
      }
      @media (max-width: 768px) {
        h2 {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 3px;
        }
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
