#graphCardSection {
  background: var(
    --gr01,
    linear-gradient(180deg, #ebf5fb 0%, #f5fafd 51%, #fff 100%)
  );
  padding-bottom: 15%;
  .graphCardBox {
    width: 74%;
    margin: 0 auto;
    position: relative;
    height: 392px;
    margin-top: 80px;
    box-sizing: border-box;

    .card {
      border-radius: 13.5px;
      color: white;
      padding: 2.5%;
      box-sizing: border-box;
      z-index: 1;
      width: 352.35px;
      height: 168.75px;
      transition: transform 0.5s ease-in-out;
      position: absolute;
      &.card1 {
        background: linear-gradient(180deg, #3288ff 0%, #2164c0 100%);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);

        left: 0;
        top: 0;
        &.zoomed,
        &:hover {
          cursor: pointer;
          transform: translate(50%, 50%) scale(2);
        }
        .graphContainer {
          right: 0;
          bottom: 0;
        }
      }
      &.card2 {
        z-index: 1;
        flex-shrink: 0;
        background: linear-gradient(180deg, #003d99 0%, #001f4d 100%);
        box-shadow: 0px 4.4px 33px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        transform: scale(1);
        right: 0;
        bottom: 0;
        p {
          text-align: right;
        }
        &.zoomed,
        &:hover {
          transform: translate(-50%, -50%) scale(2);
        }
        .graphContainer {
          left: 0;
          top: 0;
        }
      }
      &.zoomed,
      &:hover {
        cursor: pointer;
        border-radius: 13.5px;
        .graphContainer {
          border-radius: 12.5px 0;
        }
        > span {
          font-size: 15px;
          margin-bottom: 7.5px;
        }
        > p {
          font-size: 10px;
        }
      }

      > span {
        font-size: 16.5px;
        font-weight: 700;
        display: block;
        margin-bottom: 6.5px;
        transition: 0.2s ease-in-out;
      }
      > p {
        font-size: 11px;
        font-weight: 250;
        transition: 0.2s ease-in-out;
      }
    }
    .graphContainer {
      width: 64.5%;
      height: 68%;
      flex-shrink: 0;
      background-color: #fff;
      border-radius: 20px 0px;
      z-index: 2;
      box-sizing: border-box;
      position: absolute;
      transform: translateZ(0);
      transition: border-radius 0.5s;
      .graph {
        width: 80%;
        height: 75%;
        margin: 5% auto 0 auto;
        padding: 0 5%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        box-sizing: border-box;
        background-image: repeating-linear-gradient(
          to top,
          rgba(217, 217, 217, 0.2),
          rgba(217, 217, 217, 0.2) 1px,
          transparent 1px,
          transparent 14%
            /* 이 부분은 컨테이너 너비에 따라 조정해야 할 수 있습니다. */
        );
      }
      .bar {
        width: 12%;
        background: #6cbdff;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        color: white;
        font-weight: bold;
        position: relative;

        &:nth-child(1) {
          span {
            opacity: 0.5;
          }
        }
        &:nth-last-child(1) {
          background: linear-gradient(0deg, #06f 0%, #003d99 100%);
        }
        > p {
          position: absolute;
          bottom: -10px;
          color: #909090;
          text-align: center;
          font-size: 5.4px;
          font-weight: 500;
          line-height: 141%;
          letter-spacing: -0.432px;
          width: 300%;
        }
        > span {
          position: absolute;
          width: 300%;
          text-align: center;
          top: 4px;
          font-size: 6.3px;
          font-weight: 700;
          color: #fff;
        }
      }

      .bar::after {
        content: attr(--bar-value);
        position: absolute;
        top: -20px;
      }
    }
  }
}
@media (max-width: 768px) {
}
