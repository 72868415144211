#CardListSection {
  .cardBox {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    margin-top: 80px;
    .card {
      width: 360px;
      height: 100%;
      padding: 40px;
      border-radius: 30px;
      box-sizing: border-box;
      position: relative;
      background-repeat: no-repeat;
      overflow: hidden;
      // &:nth-of-type(1) {
      //   background-position: 80%;
      // }
      // &:nth-of-type(2) {
      //   background-position: 80%;
      // }
      // &:nth-of-type(3) {
      //   background-position: 30%;
      // }
      p {
        position: absolute;
        z-index: 2;
      }
      p:nth-of-type(1) {
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: -0.8px;
      }
      p:nth-of-type(2) {
        color: #fff;
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -1.04px;
        top: 23%;
      }
      /* 배경 이미지 뒤에 어두운 오버레이 추가 */
      &::after {
        content: ""; /* 빈 콘텐츠 */
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black; /* 배경을 어두운 반투명 검정색으로 설정 */
        opacity: 0.4;
        z-index: 1; /* 오버레이가 텍스트보다 아래에 보이도록 설정 */
      }
    }
  }
}
@media (max-width: 768px) {
}
