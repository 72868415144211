#ServicePricing {
  background: var(
    --gr01,
    linear-gradient(180deg, #ebf5fb 0%, #f5fafd 51%, #fff 100%)
  );
  .plansWrapper {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 10%;
    .plan {
      width: 263px;
      height: 492px;
      border-radius: 30px;
      padding: 5% 3%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      &:nth-child(1) {
        border: 1px solid #d8e7ff;
        background: #fff;
      }
      &:nth-last-child(1) {
        border: 1px solid #d8e7ff;
        background: #002d70;
        .title,
        .price,
        .detailsList {
          color: #fff;
        }
      }
      .title {
        color: #0b1115;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 141%; /* 28.2px */
        letter-spacing: -0.8px;
      }
      .price {
        color: #0b1115;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        line-height: 141%; /* 49.35px */
        letter-spacing: -1.4px;
      }
      .summaryPoints {
        color: #909090;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 141%; /* 22.56px */
        letter-spacing: -0.64px;
        margin: 10px 0 30px 0;
      }
      .ctaButton {
        display: flex;
        width: 168px;
        height: 48px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        background: #06f;
        color: white;
        font-size: 20px;
        font-weight: 700;
        line-height: 141%; /* 28.2px */
        letter-spacing: -0.8px;
        border: none;
        margin-bottom: 30px;
        cursor: pointer;
      }
      .detailsList {
        color: #0b1115;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        line-height: 141%; /* 22.56px */
        letter-spacing: -0.64px;
      }
    }
  }
}
