.footer {
  background: #263b5a;
  box-sizing: border-box;
  padding: 60px 0;
  width: 100%;
  overflow: hidden;
  > div {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 1120px) {
      & {
        width: 88%;
      }
    }
  }
  h1 {
    height: 70px;
    width: auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    img {
      height: 100%;
    }
    @media (max-width: 1120px) {
      & {
        position: static;
        transform: translateY(0%);
        width: 25%;
        height: auto;
        margin-bottom: 2.5vw;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    @media (max-width: 768px) {
      & {
        position: static;
        transform: translateY(0%);
        width: 30%;
        height: auto;
        margin-bottom: 4vw;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
    &.copyright {
      margin-bottom: 17px;
    }
    @media (max-width: 1120px) {
      & {
        font-size: 2vw;
        line-height: 3vw;
        &.copyright {
          margin-bottom: 0px;
        }
      }
    }
    @media (max-width: 768px) {
      & {
        font-size: 2.2vw;
        line-height: 3.5vw;
        &.copyright {
          margin-bottom: 0px;
        }
      }
    }
  }

  ul.policy_links {
    margin-top: 21px;
    list-style: none;
    height: 30px;
    line-height: 30px;
    text-align: left;
    li {
      font-size: 12px;
      width: auto;
      height: 100%;
      line-height: 30px;
      text-align: left;
      float: left;
      margin-right: 20px;
      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.5);

        font-weight: bold;
        font-size: 12px;
      }
      @media (max-width: 1120px) {
        & {
          a {
            font-size: 2vw;
          }
        }
      }
      @media (max-width: 768px) {
        & {
          a {
            font-size: 2.2vw;
          }
        }
      }
    }
  }
  ul.sns {
    display: flex;
    gap: 10px;
    margin-top: 25px;
    li {
      width: 30px;
      height: 30px;
      @media (max-width: 768px) {
        & {
          width: 25px;
          height: 25px;
        }
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        img {
          width: 100%;
          filter: brightness(1) invert(1);
          opacity: 0.8;
        }
      }
    }
  }
}
