#TextBanner {
  width: 100%;
  background: #0b1115;
  padding: 100px 0px;

  .textBannerInner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #fff;
      font-size: 30px;
      font-style: normal;
      line-height: 143%; /* 48.62px */
      letter-spacing: -1.2px;
    }
    button {
      border-radius: 33.5px;
      background: #06f;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.96px;
      padding: 1% 2%;
      border: none;
      cursor: pointer;
      a {
        width: 100%;
        height: 100%;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
