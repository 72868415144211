#TeamStructure {
  background: linear-gradient(180deg, #fff 70%, #f9f9f9 100%);
  .teamGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 필요 시 2열로 조정
    gap: 40px;
    margin-top: 80px;
  }

  .teamCard {
    text-align: center;

    img {
      width: 168px;
      height: auto;
      object-fit: contain;
    }

    h4 {
      color: #004fc6;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 141%; /* 28.2px */
      letter-spacing: -0.8px;
      margin: 28px 0 5px 0;
    }

    p {
      color: #909090;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 141%; /* 22.56px */
      letter-spacing: -0.64px;
    }
  }
}
